import React, { useEffect, Suspense, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import {
  Login,
  Root,
  Splash,
  Component,
  Main,
  List,
  PlanList,
  Detail,
  InquiryList,
  OrderList,
  Market,
  MarketDetail,
  RegisteredProduct,
  Notice,
  NoticeDetail,
  ReceivedScore,
  ReceivedReview,
  LoginRedirect,
  SadaDream,
  MainSadadream,
  MainGuide,
  SadaDetail,
  Search,
  DreamTalkList,
  DreamTalk,
  DreamTalkDetail,
  TalkDealReview,
  TalkDealReviewDetail,
  TransactionInfo,
  Join,
  LoginCheck,
  AppLoginNaver,
  AppLoginKakao,
  AppLoginFacebook,
  AppLoginApple,
  OnBoarding,
  Landing,
  Alarm,
  PostSada,
  PostDream,
  BringPost,
  SendPost,
  BringEdit,
  SendEdit,
  DreamEdit,
  Delivery,
  DeliveryDetail,
  DirectDeal,
  DealingCancel,
  Cart,
  Payment,
  PaymentRedirect,
  Order,
  Mypage,
  ReceivedScore_mypage,
  MyShop,
  ProfileEdit,
  MyShopBadge,
  RegisterPro,
  MypageNotice,
  MypageNoticeDetail,
  NoticeRegister,
  FreeDelivery,
  PickList,
  Keyword,
  Setting,
  SettingAccount,
  SettingSocialAccount,
  SettingAccountWithdraw,
  WithdrawDetail,
  BlackList,
  Event,
  EventDetail,
  Service,
  Terms,
  MyPageAlarm,
  Customer,
  CustomerQna,
  QnaDetail,
  QnaSubmit,
  Often,
  Coupon,
  CouponSubmit,
  PhoneRedirect,
  Transport,
  AccountSubmit,
  ReviewComplete,
  OrderReviewComplete,
  OrderReviewDetail,
  DeliverySelect,
  CouponQR,
  MannerReviewDetail,
  BrandList,
  KeywordList,
  LuckydrawDetail,
  LuckyPayment,
  LuckyComplete,
  MypageLuckyDetail,
} from "../pages";
import { api } from "../utils/api";
import { SWRConfig } from "swr";
import { ThemeProvider } from "@emotion/react";

import GlobalStyles from "../assets/styles/GlobalStyles";
import theme from "../assets/styles/theme";
import PrivateRoute from "../components/PrivateRoute";

//애널리틱스 추가
import ReactGA from "react-ga";
import WebRoute from "../components/WebRoute";

import { hot } from "react-hot-loader";
import Loading from "../components/Loading";
import { initialize } from "../utils/initialize";
import auth from "../utils/auth";
import Echo from "laravel-echo/dist/echo";
import socketio from "socket.io-client";
import { useSetRecoilState } from "recoil";
import { socketState } from "../utils/atoms";

// import { datadogRum } from '@datadog/browser-rum';

ReactGA.initialize("UA-175344540-1");

const App = () => {
  const location = useLocation();

  const locationPage = async (page) => {
    const {
      data: { success },
    } = await api.post(`/page/view?page=${page}`);
  };

  useEffect(() => {
    if (location.pathname.includes("/sada-dream/detail")) {
      locationPage(location.pathname);
    }
  }, [location.pathname]);

  // useEffect(() => {
  //     datadogRum.init({
  //         applicationId: '8f11da1d-5aa8-4d3f-b47d-b12ddf44c2ae',
  //         clientToken: 'pubf0e0d47e614da1cff35b783aa27cc4b9',
  //         site: 'us5.datadoghq.com',
  //         service: 'sadadream',
  //         env: 'prod',
  //         sessionSampleRate: 100,
  //         sessionReplaySampleRate: 100,
  //         trackUserInteractions: true,
  //         trackResources: true,
  //         trackLongTasks: true,
  //         defaultPrivacyLevel: 'allow'
  //     });
  // }, []);

  //애널리틱스 추가
  const logPageView = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);

    return null;
  };

  const fetcher = (url, params) => {
    return api.get(url, { params }).then((res) => {
      return res.data?.data || res.data;
    });
  };

  const swrConfig = {
    fetcher,
  };

  const handleVisit = async () => {
    const {
      data: { success },
    } = await api.post("/visit");

    if (success) {
      if (window.sessionStorage.getItem("visit") === true) {
        setTimeout(() => {
          handleVisit();
        }, 1800000);
      }
    }
  };

  useEffect(() => {
    if (window.location.href.includes("naver#access_token")) {
      window.location.href = window.location.href.replace("#", "?");
    }
    if (!window.location.href.includes("/sada-dream")) {
      sessionStorage.removeItem("sadaBrand");
      if (!window.location.href.includes("/sada-dream/detail")) {
        sessionStorage.removeItem("sadadream_inclusion");
        sessionStorage.removeItem("sadadream_soldInclusion");
      }
    }
    if (!window.location.href.includes("/brand")) {
      if (!window.location.href.includes("/sada-dream/detail")) {
        sessionStorage.removeItem("brand_inclusion");
        sessionStorage.removeItem("brand_soldInclusion");
      }
    }
    if (
      !window.location.href.includes("/sada-dream") ||
      !window.location.href.includes("/sada-dream/detail")
    ) {
      localStorage.removeItem("main");
      localStorage.removeItem("sub");
    }
  }, [window.location.href]);

  const checkForAccess = () => {
    var varUA = navigator.userAgent;
    if (auth.getToken() !== null) {
      if (varUA.match("sadadream/Android") !== null) {
        window.sadadream.postMessage(
          JSON.stringify({ type: "autoLogin", token: auth.getToken() })
        );
      } else if (varUA.match("sadadream/iOS") !== null) {
        window.webkit.messageHandlers.autoLoginHandler.postMessage(
          auth.getToken()
        );
      }
    }
  };

  const setSocket = useSetRecoilState(socketState);

  useEffect(() => {
    if (window.sessionStorage.getItem("visit") === null) {
      handleVisit();
      window.sessionStorage.setItem("visit", true);
    }

    localStorage.removeItem("iframe");
    localStorage.removeItem("dreammarketLoding");

    initialize();
    checkForAccess();

    const echo = new Echo({
      host: process.env.REACT_APP_HOST,
      broadcaster: "socket.io",
      client: socketio,
    });

    echo
      .channel("laravel_database_chat")
      .listen("MessagePushed", (e) =>
        setSocket({ socket: (prev) => prev++, e: e })
      );
    return () => echo.leaveChannel("laravel_database_chat");
  }, []);

  const renderLoader = () => <Loading />;

  return (
    <SWRConfig
      value={{
        ...swrConfig,
        revalidateOnFocus: false,
        errorRetryCount: 0,
      }}
    >
      <Suspense fallback={renderLoader()}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Route path="/" component={logPageView} />
          <Switch>
            <Route exact path="/component" component={Component} />
            <WebRoute exact path="/" component={Splash} />
            <WebRoute exact path="/root" component={Root} />
            {/* <Route exact path="/landing" component={Landing} /> */}

            <WebRoute exact path="/join" component={Join} />
            <WebRoute exact path="/login" component={Login} />
            <WebRoute exact path="/login_check" component={LoginCheck} />
            <WebRoute exact path="/login_redirect" component={LoginRedirect} />
            <WebRoute exact path="/on_boarding" component={OnBoarding} />

            <WebRoute exact path="/app/login/naver" component={AppLoginNaver} />
            <WebRoute exact path="/app/login/kakao" component={AppLoginKakao} />
            <WebRoute
              exact
              path="/app/login/facebook"
              component={AppLoginFacebook}
            />
            <WebRoute exact path="/app/login/apple" component={AppLoginApple} />

            <WebRoute exact path="/search" component={Search} />
            <WebRoute exact path="/search-main" component={MainSadadream} />
            <WebRoute exact path="/search-sada" component={SadaDream} />
            <PrivateRoute exact path="/alarm" component={Alarm} />
            <WebRoute exact path="/cart" component={Cart} />

            <PrivateRoute
              exact
              path="/payment/detail/:type"
              component={Payment}
            />
            <PrivateRoute
              exact
              path="/payment/complete"
              component={PaymentRedirect}
            />

            <PrivateRoute exact path="/post/sada" component={PostSada} />
            <PrivateRoute exact path="/post/bring" component={BringPost} />
            <PrivateRoute exact path="/post/send" component={SendPost} />
            <PrivateRoute exact path="/post/dream" component={PostDream} />

            <PrivateRoute exact path="/edit/bring/:id" component={BringEdit} />
            <PrivateRoute exact path="/edit/send/:id" component={SendEdit} />
            <PrivateRoute exact path="/edit/dream/:id" component={DreamEdit} />

            {/* <WebRoute exact path="/dream-market" component={Main} /> */}
            {/* <WebRoute exact path="/dream-market/:key" component={List} /> */}
            {/* <WebRoute
            exact
            path="/dream-market/plan/:planId"
            component={PlanList}
          /> */}
            {/* <WebRoute
            exact
            path="/dream-market/product/:productId"
            component={Detail}
          /> */}
            {/* <WebRoute
            exact
            path="/dream-market/inquiry/:productId"
            component={InquiryList}
          /> */}
            {/* <WebRoute
            exact
            path="/dream-market/order/:productId"
            component={OrderList}
          /> */}
            {/* <WebRoute exact path="/dream-market/market/all" component={Market} /> */}
            <WebRoute
              exact
              path="/dream-market/market/:userId"
              component={MarketDetail}
            />
            <WebRoute
              exact
              path="/registered-product/:userId"
              component={RegisteredProduct}
            />
            <WebRoute
              exact
              path="/dream-market/market/:userId/notice"
              component={Notice}
            />
            <WebRoute
              exact
              path="/dream-market/market/:userId/notice/:noticeId"
              component={NoticeDetail}
            />
            <WebRoute
              exact
              path="/dream-market/market/:userId/score"
              component={ReceivedScore}
            />
            <WebRoute
              exact
              path="/dream-market/market/:userId/review"
              component={ReceivedReview}
            />

            <PrivateRoute exact path="/dream-talk" component={DreamTalkList} />

            <PrivateRoute
              exact
              path="/dream-talk/:userId"
              component={DreamTalk}
            />
            <PrivateRoute
              exact
              path="/dream-talk/detail/:userId"
              component={DreamTalkDetail}
            />
            <PrivateRoute
              exact
              path="/dream-talk/transaction/:roomId"
              component={TransactionInfo}
            />
            <PrivateRoute
              exact
              path="/dream-talk/cancel/:roomId"
              component={DealingCancel}
            />
            <PrivateRoute
              exact
              path="/dream-talk/review/:roomId"
              component={TalkDealReview}
            />
            <PrivateRoute
              exact
              path="/dream-talk/review/complete/:roomId"
              component={ReviewComplete}
            />
            <PrivateRoute
              exact
              path="/dream-talk/review/detail/:roomId/:reviewId"
              component={TalkDealReviewDetail}
            />

            <WebRoute exact path="/main" component={MainSadadream} preload />
            <WebRoute
              exact
              path="/review/:reviewId"
              component={MannerReviewDetail}
            />
            <WebRoute
              exact
              path="/guide/detail/:guideId"
              component={MainGuide}
            />
            <WebRoute exact path="/sada-dream" component={SadaDream} preload />
            <WebRoute
              exact
              path="/sada-dream/detail/:id"
              component={SadaDream}
            />
            <WebRoute
              exact
              path="/sadadream/detail/:id"
              component={SadaDetail}
            />
            <PrivateRoute exact path="/delivery" component={Delivery} />
            <PrivateRoute
              exact
              path="/delivery-select"
              component={DeliverySelect}
            />
            <PrivateRoute
              exact
              path="/delivery/submit"
              component={DeliveryDetail}
            />
            <PrivateRoute exact path="/direct-deal" component={DirectDeal} />

            <WebRoute exact path="/mypage" component={Mypage} />
            <PrivateRoute exact path="/myshop" component={MyShop} />
            <PrivateRoute exact path="/myshop/badge" component={MyShopBadge} />
            <PrivateRoute
              exact
              path="/mypage/register-pro"
              component={RegisterPro}
            />
            <PrivateRoute exact path="/profile-edit" component={ProfileEdit} />
            <PrivateRoute
              exact
              path="/mypage/received-score"
              component={ReceivedScore_mypage}
            />
            <WebRoute exact path="/mypage/notice" component={MypageNotice} />
            <WebRoute
              exact
              path="/mypage/notice/:noticeId"
              component={MypageNoticeDetail}
            />
            <PrivateRoute
              exact
              path="/notice/submit"
              component={NoticeRegister}
            />
            <PrivateRoute
              exact
              path="/mypage/free-delivery"
              component={FreeDelivery}
            />
            <PrivateRoute exact path="/mypage/pick" component={PickList} />
            <PrivateRoute exact path="/mypage/keyword" component={Keyword} />
            <WebRoute exact path="/mypage/setting" component={Setting} />
            <PrivateRoute
              exact
              path="/mypage/setting/account"
              component={SettingAccount}
            />
            <PrivateRoute
              exact
              path="/mypage/setting/account/social"
              component={SettingSocialAccount}
            />
            <PrivateRoute
              exact
              path="/mypage/setting/account/withdraw"
              component={SettingAccountWithdraw}
            />
            <PrivateRoute
              exact
              path="/mypage/setting/account/withdraw/detail"
              component={WithdrawDetail}
            />
            <PrivateRoute
              exact
              path="/mypage/setting/blacklist"
              component={BlackList}
            />
            <PrivateRoute exact path="/mypage/service" component={Service} />
            <PrivateRoute
              exact
              path="/mypage/service/terms"
              component={Terms}
            />
            <PrivateRoute exact path="/mypage/alarm" component={MyPageAlarm} />

            <PrivateRoute exact path="/mypage" component={Mypage} />
            <PrivateRoute exact path="/myshop" component={MyShop} />
            <PrivateRoute exact path="/myshop/badge" component={MyShopBadge} />
            <PrivateRoute
              exact
              path="/mypage/register-pro"
              component={RegisterPro}
            />
            <PrivateRoute exact path="/profile-edit" component={ProfileEdit} />
            <PrivateRoute
              exact
              path="/mypage/received-score"
              component={ReceivedScore_mypage}
            />
            <WebRoute exact path="/mypage/notice" component={MypageNotice} />
            <WebRoute
              exact
              path="/mypage/notice/:noticeId"
              component={MypageNoticeDetail}
            />
            <PrivateRoute
              exact
              path="/notice/submit"
              component={NoticeRegister}
            />
            <PrivateRoute
              exact
              path="/mypage/free-delivery"
              component={FreeDelivery}
            />
            <PrivateRoute exact path="/mypage/pick" component={PickList} />
            <PrivateRoute exact path="/mypage/keyword" component={Keyword} />
            <PrivateRoute exact path="/mypage/coupon" component={Coupon} />
            <PrivateRoute exact path="/mypage/coupon/qr" component={CouponQR} />
            <PrivateRoute
              exact
              path="/mypage/coupon/submit"
              component={CouponSubmit}
            />

            <PrivateRoute exact path="/mypage/setting" component={Setting} />
            <PrivateRoute
              exact
              path="/mypage/setting/account"
              component={SettingAccount}
            />
            <PrivateRoute
              exact
              path="/mypage/setting/account/social"
              component={SettingSocialAccount}
            />
            <PrivateRoute
              exact
              path="/mypage/setting/account/withdraw"
              component={SettingAccountWithdraw}
            />
            <PrivateRoute
              exact
              path="/mypage/setting/account/withdraw/detail"
              component={WithdrawDetail}
            />
            <PrivateRoute
              exact
              path="/mypage/setting/blacklist"
              component={BlackList}
            />
            <WebRoute exact path="/mypage/setting/event" component={Event} />
            <WebRoute
              exact
              path="/mypage/setting/event/:eventId"
              component={EventDetail}
            />
            <WebRoute
              exact
              path="/mypage/setting/service"
              component={Service}
            />
            <WebRoute
              exact
              path="/mypage/setting/service/terms"
              component={Terms}
            />
            <PrivateRoute
              exact
              path="/mypage/setting/alarm"
              component={MyPageAlarm}
            />
            <WebRoute
              exact
              path="/mypage/setting/customer"
              component={Customer}
            />
            <PrivateRoute
              exact
              path="/mypage/setting/customer/qna"
              component={CustomerQna}
            />
            <PrivateRoute
              exact
              path="/mypage/setting/customer/qna/detail/:qnaId"
              component={QnaDetail}
            />
            <PrivateRoute
              exact
              path="/mypage/setting/customer/qna/submit"
              component={QnaSubmit}
            />
            <WebRoute
              exact
              path="/mypage/setting/customer/often"
              component={Often}
            />

            <PrivateRoute exact path="/order" component={Order} />
            <PrivateRoute
              exact
              path="/order/review/complete/:id/:orderNb"
              component={OrderReviewComplete}
            />
            <PrivateRoute
              exact
              path="/order/review/detail/:reviewId/:targetId/:id/:orderNb"
              component={OrderReviewDetail}
            />
            <PrivateRoute
              exact
              path="/dream-talk/transaction/transport/:roomId"
              component={Transport}
            />
            <PrivateRoute
              exact
              path="/dream-talk/transaction/account/:roomId"
              component={AccountSubmit}
            />

            <WebRoute exact path="/phone_redirect" component={PhoneRedirect} />

            {/* 브랜드관 */}
            <WebRoute exact path="/brand/:id" component={BrandList} />
            <WebRoute exact path="/main/keyword" component={KeywordList} />

            {/* 럭키드로우 */}
            <WebRoute
              exact
              path="/lucky/detail/:luckyId"
              component={LuckydrawDetail}
            />
            <WebRoute
              exact
              path="/lucky/detail/payment/:luckyId"
              component={LuckyPayment}
            />
            <WebRoute
              exact
              path="/lucky/detail/payment/complete/:luckyId"
              component={LuckyComplete}
            />
            <WebRoute
              exact
              path="/mypage/lucky/detail/:luckyId"
              component={MypageLuckyDetail}
            />
          </Switch>
        </ThemeProvider>
      </Suspense>
    </SWRConfig>
  );
};

export default hot(module)(App);
